import React from 'react'
import { signoutUser } from '../../../../actions/auth.actions'
import LabelWithBannerNew from '../../label-with-banner-new'
import SmartLinkBasedOnUserType from '../smart-link-based-on-user-type'
import ProvenIconWithText from '../../proven-icon-with-text'
import { Typography } from '@mui/material'

//TODO remove this sx for hover once we refactor ProvenMenuItem, then start using Chip and styles in variants
const sxOfItemHover = {
  '&:hover': {
    color: 'primary.main'
  }
}

export const learnItems = [
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Our Story
      </Typography>
    ),
    link: '/why-proven/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Our Science
      </Typography>
    ),
    link: '/clinical-proof/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Our Ingredients
      </Typography>
    ),
    link: '/skincare-ingredients/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Skin Concerns
      </Typography>
    ),
    link: '/skin-concern/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Skin Types
      </Typography>
    ),
    link: '/skin-type/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Press
      </Typography>
    ),
    link: '/press/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        FAQs
      </Typography>
    ),
    link: '/faqs/',
    type: 'link'
  },
  {
    label: (
      <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
        Blog
      </Typography>
    ),
    link: '/blog/',
    type: 'link'
  }
]

export const logoutItem = {
  label: 'Log Out',
  link: '/logout/',
  type: 'dispatch',
  action: signoutUser,
  redirect: '/',
  cypressId: 'logout',
  id: 'logout'
}

export const userMenuLogoutItem = {
  ...logoutItem,
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      Logout
    </Typography>
  ),
  fitContent: true,
  itemWithoutIcon: true
}

const logoutWithIconItem = {
  ...logoutItem,
  label: (
    <ProvenIconWithText type="tab" name="logout" color="primary.main">
      <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
        Log Out
      </Typography>
    </ProvenIconWithText>
  )
}

export const productsItems = [
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-subscription">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          3-Step Skincare System
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: '3-Step Skincare System',
    type: 'link'
  },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-face-cleanser">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Personalized Cleanser
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Cleanser',
    type: 'link'
  },

  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-day-moisturizer">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Personalized Day Moisturizer
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Day Moisturizer',
    type: 'link'
  },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-night-cream">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Personalized Night Cream
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Night Cream',
    type: 'link'
  },
  { type: 'divider', noHeightDivider: true },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-eye-cream-duo/">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Eye Cream Duo
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Eye Cream Duo',
    type: 'link'
  },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-day-eye-cream">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Day Eye Cream
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Day Eye Cream',
    type: 'link'
  },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-night-eye-cream">
        <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
          Night Eye Cream
        </Typography>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Night Eye Cream',
    type: 'link'
  },
  { type: 'divider', noHeightDivider: true },
  {
    label: (
      <SmartLinkBasedOnUserType defaultPublicPagePath="/personalized-serum">
        <LabelWithBannerNew>
          <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
            All-In-One Serum
          </Typography>
        </LabelWithBannerNew>
      </SmartLinkBasedOnUserType>
    ),
    itemName: 'Personalized Serum',
    type: 'link'
  }
]

export const mobileMenuDashboardItems = [
  {
    label: 'Home',
    itemName: 'My Skincare',
    link: '/account/',
    type: 'link'
  },
  {
    label: 'Personalize',
    link: '/account/skin/',
    type: 'link',
    itemName: 'Personalize'
  },
  {
    label: 'Subscriptions',
    link: '/account/subscriptions/',
    type: 'link',
    itemName: 'Subscriptions'
  },
  {
    label: 'Shop',
    link: '/account/shop/',
    type: 'link',
    itemName: 'Shop'
  },
  {
    label: 'Customer Care',
    itemName: 'Customer Care',
    link: '/account/support/',
    type: 'link'
  },
  {
    label: 'Account Settings',
    itemName: 'Account Settings',
    link: '/account/settings/info/',
    type: 'link'
  },
  {
    label: 'Order History',
    itemName: 'Order History',
    link: '/account/settings/orders/',
    type: 'link'
  }
]

export const viewMySkincareResultsItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      View My Skincare Results
    </Typography>
  ),
  link: '/account/congratulations',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}
export const viewMyEyeCreamResultsItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      View My Eye Cream Results
    </Typography>
  ),
  link: '/account/congratulations/eye',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}
export const viewMySerumResultsItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      View My Serum Results
    </Typography>
  ),
  link: '/account/congratulations/serum',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}

export const eyeQuizItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      Take My Eye Cream Quiz
    </Typography>
  ),
  link: '/quiz/eye/start',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}

export const serumQuizItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      Take My Serum Quiz
    </Typography>
  ),
  link: '/quiz/serum/start',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}

export const skinQuizItem = {
  label: (
    <Typography sx={sxOfItemHover} variant="body2" color="gray.darkGray" component="p">
      Take My Skin Quiz
    </Typography>
  ),
  link: '/quiz/start',
  type: 'link',
  fitContent: true,
  itemWithoutIcon: true
}

export const accountWithRUDAccess = [
  {
    label: (
      <ProvenIconWithText type="tab" name="home" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Home
        </Typography>
      </ProvenIconWithText>
    ),
    itemName: 'My Skincare',
    link: '/account/',
    type: 'link',
    fitContent: true
  },
  {
    label: (
      <ProvenIconWithText type="tab" name="personalize" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Personalize
        </Typography>
      </ProvenIconWithText>
    ),
    link: '/account/skin/',
    type: 'link',
    itemName: 'Personalize',
    fitContent: true
  },
  {
    label: (
      <ProvenIconWithText type="tab" name="subscriptions" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Subscriptions
        </Typography>
      </ProvenIconWithText>
    ),
    link: '/account/subscriptions/',
    type: 'link',
    itemName: 'Subscriptions',
    fitContent: true
  },
  {
    label: (
      <ProvenIconWithText type="tab" name="shop" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Shop
        </Typography>
      </ProvenIconWithText>
    ),
    link: '/account/shop/',
    type: 'link',
    itemName: 'Shop',
    fitContent: true
  },
  { type: 'divider', noHeightDivider: true },
  {
    label: (
      <ProvenIconWithText type="tab" name="customer-care" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Customer Care
        </Typography>
      </ProvenIconWithText>
    ),
    itemName: 'Customer Care',
    link: '/account/support/',
    type: 'link'
  },
  {
    label: (
      <ProvenIconWithText type="tab" name="account" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Account Settings
        </Typography>
      </ProvenIconWithText>
    ),
    itemName: 'Account Settings',
    link: '/account/settings/info/',
    type: 'link'
  },
  {
    label: (
      <ProvenIconWithText type="dashboard" name="order-history" color="primary.main">
        <Typography sx={sxOfItemHover} variant="title2" component="p" color="primary">
          Order History
        </Typography>
      </ProvenIconWithText>
    ),
    itemName: 'Order History',
    link: '/account/settings/orders/',
    type: 'link'
  },
  logoutWithIconItem
]

export const emptyUserItems = [skinQuizItem, eyeQuizItem, serumQuizItem, { ...userMenuLogoutItem }]

export const headerItemsWithoutSkinQuizMenu = [
  {
    label: 'Products',
    subItems: productsItems,
    type: 'link'
  },
  {
    label: 'Learn',
    subItems: learnItems,
    type: 'link'
  },
  {
    label: 'Reviews',
    link: '/reviews/',
    type: 'link'
  }
]
